import './App.css';
import {useWebApp, WebAppProvider} from "@vkruglikov/react-telegram-web-app";
import {useEffect} from "react";
import {BarcodeScanner1} from "./BarcodeScanner1";

function App() {

    const WebApp = useWebApp()

    useEffect(() => {
        WebApp.ready();
        WebApp.expand()
    }, [WebApp])

    return (
        <WebAppProvider>
            <div className="App">
                <BarcodeScanner1 tg={WebApp}/>
            </div>
        </WebAppProvider>
    );
}

export default App;
