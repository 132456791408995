import { useEffect, useRef, useState } from "react";
import { BarcodeFormat, BrowserMultiFormatReader } from "@zxing/library";

import mask from "./content/image/mask.png";

export const BarcodeScanner1 = ({ tg }) => {
  const videoRef = useRef(null);
  const reader = useRef(new BrowserMultiFormatReader());
  let answer = null;
  const canvas = useRef(null);
  const [videoInit, setVideoInit] = useState(false);

  useEffect(() => {
    if (!videoRef.current) return;
    reader.current.decodeFromConstraints(
      {
        video: {
          facingMode: "environment",
        },
      },
      videoRef.current,
      (result, error) => {
        if (result) {
          tg.HapticFeedback.notificationOccurred("success");
          answer = JSON.stringify({
            barcode: result.getText(),
            type: Object.keys(BarcodeFormat).find(
              (name) => BarcodeFormat[name] === result.getBarcodeFormat()
            ),
          });
          tg.sendData(answer);
        }
      }
    );
  }, [videoRef, tg]);

  useEffect(() => {
    if (canvas.current) {
      let ctx = canvas.current.getContext("2d");
      ctx.fillRect(0, 0, 200, 200);
      ctx.fillStyle = "rgba(0, 0, 100, 0)";
      ctx.fill();
      ctx.strokeStyle = "rgba(255,255,255, 1)";
    }
  }, [canvas]);

  return (
    <div className="divScanVideo">
      {videoInit ? (
        <div id="videoCanvas">
          <div>
            <img src={mask} alt="Mask" />
            <div></div>
          </div>
        </div>
      ) : null}
      {reader.current ? reader.current.canvas : null}
      <video
        id="webCamera"
        ref={videoRef}
        autoFocus={true}
        autoPlay={true}
        muted={true}
        onPlay={() => setVideoInit(true)}
      />
    </div>
  );
};
